import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Sitemap, SitemapMeta } from "../data/seo-meta"

const SitemapPage = () => {
  return (
    <Layout>
      <SEO
        description={SitemapMeta.description}
        path={SitemapMeta.path}
        title={SitemapMeta.title}
      />
      <div className="container py-20 md:py-24">
        <h1 className="h1">Welcome to Slice</h1>
        <h2 className="h2 mb-16">
          The leader in on&#8209;demand, all digital, insurance solutions.
        </h2>
        {Sitemap.map((page) => (
          <div key={page.path}>
            <Link className="h3 underline mb-4" to={page.path}>
              {page.title}
            </Link>
            <p className="p1 mb-16">{page.description}</p>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default SitemapPage
